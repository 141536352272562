import 'normalize.css';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import Typed from 'typed.js';
// import './icons/menu.svg'
// import XIcon from './icons/menu.svg'

// document.write('<br>Hello World!')

const animationInput = document.querySelector('.animation-input')
const downArrow = document.querySelector('.animation-down-arrow')
const nextElement = document.querySelector('.section-not-alone')
const sectionCalendar = document.querySelector('.section-calendar')
// const fixedBox = document.querySelector('.fixed-box')
const scrollMoreLink = document.querySelector('.scroll-link--more')
const scrollMeetingLink = document.querySelector('.scroll-link--meeting')

scrollMoreLink.addEventListener('click', event => {
  event.preventDefault()
  event.stopPropagation()
  nextElement.scrollIntoView({ block: 'start',  behavior: 'smooth' });
})

scrollMeetingLink.addEventListener('click', event => {
  event.preventDefault()
  event.stopPropagation()
  sectionCalendar.scrollIntoView({ block: 'start',  behavior: 'smooth' });
})

// animationInput.addEventListener('click', event => {
//   event.preventDefault()
//   event.stopPropagation()
//   console.log('test loggy')
//   nextElement.scrollIntoView({ block: 'start',  behavior: 'smooth' });
// })

// downArrow.addEventListener('click', event => {
//   event.preventDefault()
//   event.stopPropagation()
//   console.log('test log')
//   nextElement.scrollIntoView({ block: 'start',  behavior: 'smooth' });
// })

// Fixed box
// const observer = new IntersectionObserver(entries => {
//   // console.log(entries);
//   if (entries[0].isIntersecting) {
//     // console.log("yay it's in")
//     fixedBox.classList.add('is-active')
//   } else {
//     // console.log("no it's out")
//   }
// }, {rootMargin: "0px 0px -200px 0px"});
// observer.observe(nextElement);


const sliders = Array.from(document.querySelectorAll('.slider')).forEach(slider => {
  SwiperCore.use([Navigation, Pagination, Autoplay]);
  const sliderContainer = slider.querySelector('.swiper-container')
  const leftArrow = slider.querySelector('.slider-arrow--left')
  const rightArrow = slider.querySelector('.slider-arrow--right')
  let sliderOptions

  if (slider.classList.contains('slider-others')) {
    sliderOptions = {
      loop: true,
      speed: 500,
      pagination: {
        type: 'bullets',
        el: '.swiper-pagination',
        clickable: true,
        bulletActiveClass: 'slider-bullet--active',
        bulletClass: 'slider-bullet',
        bulletElement: 'span',
      },
      autoplay: {
        delay: 5000,
      },
    }
  } else {
    sliderOptions = {
      loop: true,
      pagination: {
        type: 'bullets',
        el: '.swiper-pagination',
        clickable: true,
        bulletActiveClass: 'slider-bullet--active',
        bulletClass: 'slider-bullet',
        bulletElement: 'span',
      }
    }
  }
  // console.log(slider)
  // console.log(slider.classList.contains('slider-others'))
  // const swiper = new SwiperCore(sliderContainer, {
  //   loop: true,
  //   pagination: {
  //     type: 'bullets',
  //     el: '.swiper-pagination',
  //     clickable: true,
  //     bulletActiveClass: 'slider-bullet--active',
  //     bulletClass: 'slider-bullet',
  //     bulletElement: 'span',
  //   },
  // });
  const swiper = new SwiperCore(sliderContainer, sliderOptions);

  leftArrow.addEventListener('click', event => {
    event.preventDefault()
    event.stopPropagation()
    // console.log("clicked left");
    swiper.slidePrev(200, true)
  })

  rightArrow.addEventListener('click', event => {
    event.preventDefault()
    event.stopPropagation()
    // console.log("clicked right");
    swiper.slideNext(200, true)
  })
})

// us slider start
// SwiperCore.use([Navigation, Pagination]);
// const swiper = new SwiperCore('.swiper-container', {
//   // Optional parameters
//   loop: true,

//   // If we need pagination
//   pagination: {
//     type: 'bullets',
//     el: '.swiper-pagination',
//     clickable: true,
//     bulletActiveClass: 'slider-bullet--active',
//     bulletClass: 'slider-bullet',
//     bulletElement: 'span',
//   },

//   // Navigation arrows
//   // navigation: {
//   //   nextEl: '.swiper-button-next',
//   //   prevEl: '.swiper-button-prev',
//   // },
// });

// const slider = document.querySelector('.slider')
// const leftArrow = slider.querySelector('.slider-arrow--left')
// const rightArrow = slider.querySelector('.slider-arrow--right')

// // leftArrow.addEventListener('click', () => {
// //   console.log("clicked left");
// // })

// // rightArrow.addEventListener('click', () => {
// //   console.log("clicked right");
// // })

// leftArrow.addEventListener('click', event => {
//   event.preventDefault()
//   event.stopPropagation()
//   console.log("clicked left");
//   swiper.slidePrev(200, true)
// })

// rightArrow.addEventListener('click', event => {
//   event.preventDefault()
//   event.stopPropagation()
//   console.log("clicked right");
//   swiper.slideNext(200, true)
// })
// us slider end


// others slider start
// const othersSlider = document.querySelector('.others-slider')
// const othersSliderContainer = othersSlider.querySelector('.swiper-container')
// const othersLeftArrow = othersSlider.querySelector('.slider-arrow--left')
// const othersRightArrow = othersSlider.querySelector('.slider-arrow--right')

// SwiperCore.use([Navigation, Pagination]);
// const othersSwiper = new SwiperCore(othersSliderContainer, {
//   // Optional parameters
//   loop: true,

//   // If we need pagination
//   pagination: {
//     type: 'bullets',
//     el: '.swiper-pagination',
//     clickable: true,
//     bulletActiveClass: 'slider-bullet--active',
//     bulletClass: 'slider-bullet',
//     bulletElement: 'span',
//   },
// });

// othersLeftArrow.addEventListener('click', event => {
//   event.preventDefault()
//   event.stopPropagation()
//   console.log("clicked left");
//   othersSwiper.slidePrev(200, true)
// })

// othersRightArrow.addEventListener('click', event => {
//   event.preventDefault()
//   event.stopPropagation()
//   console.log("clicked right");
//   othersSwiper.slideNext(200, true)
// })
// others slider end

let body = document.querySelector('body')
let menuButton = document.querySelector('.header-menu-button')
let mobileMenu = document.querySelector('.mobile-menu')

menuButton.addEventListener('click', () => {
  mobileMenu.classList.toggle('is-open')
  if (mobileMenu.classList.contains('is-open')) {
    body.classList.add('no-scroll')
    menuButton.classList.add('is-open')
  } else {
    body.classList.remove('no-scroll')
    menuButton.classList.remove('is-open')
  }
})

function startSearchfieldAnimation() {
  const input = document.querySelector('.animation-input-inner')
  var typedOptions

  if (input) {
    if (window.innerWidth <= 700) {
      typedOptions = {
        strings: ['Diagnose MS - Was jetzt?', 'Diagnose MS - Was bedeuted das?', 'Diagnose MS - Wer versteht mich?'],
        startDelay: 1000,
        typeSpeed: 30,
        loop: false
      }
    } else {
      typedOptions = {
        strings: ['Diagnose Multiple Sklerose - Was jetzt?', 'Diagnose Multiple Sklerose - Was bedeuted das?', 'Diagnose Multiple Sklerose - Wer versteht mich?'],
        startDelay: 1000,
        typeSpeed: 30,
        loop: false
      }
    }
    var typed = new Typed(input, typedOptions);
  }

}
startSearchfieldAnimation()

